<template>
  <div >

    <Table
      v-if="table"
      :url="url"
      :fields="fields"
      :body="body"
      :updateData="makeCall"
      :showSearch="false"
      :page="'transactions'"
      :title="$t('transactions')"
      :actionButton="
        data && data.role != null && data.role == 'debtor' ? button : false
      "
      @actionButtonTab="actionButtonTab"
    >
      <template #cell(id)="data">
        <b-link @click="showTransaction(data.item)">
          {{ data.item.id }}
        </b-link>
      </template>
       <template #cell(amount)="data">
        <span v-if="data.item.amount">{{data.item.amount | formatAmount}}</span>
        <span v-else>-</span>
      </template>
      <template #cell(currency)="">
        <span>CHF</span>
      </template>
      <template #cell(status)="data">
        <span v-if="data.value == 0">{{ $t("pending") }} </span>
        <span v-if="data.value == 1">{{ $t("approved") }}</span>
        <span v-if="data.value == 2">{{ $t("declined") }}</span>
        <span class="text-secondary" v-if="data.value == 3">{{
          $t("proof_of_ownership_is_in_progress")
        }}</span>
      </template>
    </Table>
    <Deposit
      v-else
      @showTable="showTable"
      :deposit="deposit"
      @storeDeposit="storeDeposit"
    ></Deposit>
  </div>
</template>
<script>
import Table from "../../Common/Table2.vue";
import Deposit from "./Depoist.vue";
export default {
  components: { Table, Deposit },
  data() {
    return {
      url: "/transactions/admin",
      button: {
        text: "deposit",
        link: "",
        color: "primary",
      },
      fields: [
        {
          key: "id",
          label: "id",
        },
        {
          key: "type_description",
          label: "Description",
        },
        {
          key: "currency",
          label: "currency",
        },
        {
          key: "amount",
          label: "amount",
        },
        {
          key: "status",
          label: "status",
        },
        {
          key: "reference_number",
          label: "reference_number_payment_reason",
        },
      ],
      table: true,
      deposit: null,
      transactions: [],
      body: {
        company_id: this.$route.params.id,
      },
    };
  },

  created() {},
  props:['companyData', 'data'],
  methods: {
    storeDeposit() {
      this.getTransactions();
    },
    showTransaction(item) {
      this.deposit = item;
      this.table = false;
    },
    actionButtonTab() {
      this.deposit = null;
      this.table = false;
    },
    makeCall() {
      this.getTransactions();
    },
    showTable() {
      this.table = true;
    },
    getTransactions() {
      this.$http
        .post("/transactions/admin", { comapny_id: this.$route.params.id })
        .then((res) => {
          if (res) {
            this.transactions = res.data.value.data;
            this.table = true;
          }
        });
    },
  },
};
</script>
